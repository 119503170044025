<template>
  <div class="ps-lg-7 pt-4">
    <h1 class="fs-24 fw-700 opacity-80 mb-5 mt-3">{{ $t("my_querries") }}</h1> 
    <div v-if="loading">
      <div class="text-center">
        <div>{{ $t("loading_please_wait") }}</div>
      </div>
    </div>
    <div v-else>
      <v-card v-if="getProductQuerries.length > 0">  
        <div v-for="(conversation, i) in getProductQuerries" :key="i" :class="[ 'py-2', { 'border-bottom' : i+1 != getProductQuerries.length}]">
          <ConversationBox :conversation="conversation" @refresh="getList(1)"/>   
        </div> 
      </v-card>
      <div v-else class="text-center">
        <div>{{ $t("no_conversation_found") }}</div>
      </div> 
    </div> 
    <div class="text-center" v-if="totalPages > 1">
      <v-pagination
        v-model="currentPage"
        @input="getList"
        :length="totalPages"
        prev-icon="la-angle-left"
        next-icon="la-angle-right"
        :total-visible="7"
        elevation="0"
        class="my-4"
      ></v-pagination>
    </div>
  </div>
</template>

<script> 
// import { mapGetters, mapActions } from 'vuex'
import ConversationBox  from '../../components/conversation/ConversationBox.vue'
export default {
    data: () => ({
        loading: false,
        currentPage: 1,
        totalPages: 1,
        getProductQuerries: [],
        remarkSwitch: ''
    }),
    components:{
        ConversationBox
    }, 
    computed: {
        // ...mapGetters('app',[
        //     'getProductQuerries'
        // ]),
    },

    methods: {
      // ...mapActions('app', ['fetchProductMessage'])
      async getList(number) {
        this.loading = true
        try {
          const res = await this.call_api(
            'get',
            `user/querries?page=${number}`
          )
          const {
            data,
            meta: { last_page, current_page }
          } = res.data

          if (res.data.success) {
            this.getProductQuerries = data
            this.totalPages = last_page
            this.currentPage = current_page
          } else {
            this.snack({
              message: this.$i18n.t('something_went_wrong'),
              color: 'red'
            })
          }
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },
      async getRemarkSwitch() {
        try {
          const res = await this.call_api('get', 'get-business-settings/customer_to_seller_remark_switch')

          this.remarkSwitch = res.data.data ? res.data.data.customer_to_seller_remark_switch : ''
        } catch (err) {
          console.log(err)
        }
      }
    },

    created() {
      this.getRemarkSwitch()
      this.getList(1)
      // this.fetchProductMessage()
    }
}
</script>